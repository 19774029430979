<template>
<FocusTrap>
  <div>
    <h3>Reporting</h3>
  </div>
</FocusTrap>
</template>

<script>
export default {
  name: 'Reporting'
}
</script>

<style scoped>

</style>
